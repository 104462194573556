import axios from 'axios';

const state = {
	structure: []
};

const actions = {
	async fetchStructure({ commit }, id) {
		const data = await axios.get(`/contact-form-7/v1/contact-forms/${id}/feedback/schema`)
		if (data.data.rules.length)
			commit("updateStructure", data.data.rules);
	},
    async insertComplaintSuggestionForm(
        { commit },
        { id, formData, successCallback }
    ) {
        console.log("id", id);
        await axios.post(
            `/contact-form-7/v1/contact-forms/${id}/feedback`,
            formData,
            {
                headers: { "Content-Type": "multipart/form-data" },
            }
        );
        // if (response.data.status) {
        //   errorCallback(response.data.status);
        //   return;
        // }
        commit("updateFormEmpty", true);
        successCallback();
    },
};

const getters = {
	getStructure: state => state.structure
};

const mutations = {
	updateStructure: (state, latest) => state.structure = latest,
    updateFormEmpty(state, payload) {
        const { unitTag, isEmpty } = payload;
        console.log(unitTag, isEmpty);
    },
};

export default {
	state,
	getters,
	actions,
	mutations
}