const banks = {
    "PriBank": [
        {
            "Id": 1,
            "Dega": "PriBank - Zyra Qëndrore",
            "lat": "42.639674",
            "lng": "21.150247",
            "Tel": "",
            "Address": 'Rr. "Sicilia", Kompleksi Lubjana/Kalabri, Blloku A1, Llam: A1/2, nr. 2, Prishtine'
        },
        {
            "Id": 2,
            "Dega": "PriBank - Dega Prishtinë",
            "lat": "42.65841",
            "lng": "21.154735",
            "Tel": "",
            "Address": 'Rr. "Ukshin Hoti"'
        },
        {
            "Id": 3,
            "Dega": "PriBank - Dega Prishtina 2",
            "lat": "42.65841",
            "lng": "21.154735",
            "Tel": "",
            "Address": 'Rr. ”Elez Berisha”, Zona Industriale, Prishtinë (Kolegji AAB)'
        },
        {
            "Id": 4,
            "Dega": "PriBank - Dega Pejë",
            "lat": "42.65723191135249", 
            "lng": "20.29111259713177",
            "Tel": "",
            "Address": 'Rr. Hasan Prishtina, Nr.1, (përballë Optikës Nimani)'
        },
        {
            "Id": 5,
            "Dega": "PriBank - Dega Gjilan",
            "lat": "42.65841",
            "lng": "21.154735",
            "Tel": "",
            "Address": 'Rr. "Adem Jashari"'
        },
        {
            "Id": 6,
            "Dega": "PriBank - Dega Ferizaj",
            "lat": "42.371395",
            "lng": "21.155772",
            "Tel": "",
            "Address": 'Rr. "Vllezerit Gervalla"'
        },
        {
            "Id": 7,
            "Dega": "PriBank - Dega Prizren",
            "lat": "42.216413",
            "lng": "20.726392",
            "Tel": "",
            "Address": 'Rr. "Tirana"'
        },
        {
            "Id": 8,
            "Dega": "PriBank - Dega Fushë Kosovë",
            "lat": "42.63907529062615",
            "lng": "21.0975997734977",
            "Tel": "",
            "Address": 'Rr. "Nenë Tereza"'
        },
        {
            "Id": 9,
            "Dega": "PriBank - Dega Gjakovë",
            "lat": "42.38353100765761",
            "lng": "20.42908578645229",
            "Tel": "",
            "Address": 'Rr. "Nëna Terezë, Pn"'
        },
        {
            "Id": 10,
            "Dega": "PriBank - Dega Mitrovicë",
            "lat": "42.888742829499925",
            "lng": "20.872269434077733",
            "Tel": "",
            "Address": 'Rr. "Shemsi Ahmeti, Nr.04"'
        },
        {
            "Id": 11,
            "Dega": "PriBank - Dega Drenas",
            "lat": "42.625481781672796",
            "lng": "20.89527291987945",
            "Tel": "",
            "Address": 'Rr. "Fehmi Ladrovci, Nr.5"'
        },
        {
            "Id": 12,
            "Dega": "PriBank - Dega Lipjan",
            "lat": "42.521264330840495",
            "lng": "21.121830960066536",
            "Tel": "",
            "Address": 'Rr. "Sheshi Adem Jashari, Nr. 6"'
        },
        {
            "Id": 13,
            "Dega": "PriBank - Dega Suharekë",
            "lat": "42.36270738607858",
            "lng": "20.831981739753182",
            "Tel": "",
            "Address": 'Rr. “Brigada 123” (Përballë stacionit të Policisë)'
        },
        {
            "Id": 14,
            "Dega": "PriBank - Dega Vushtrri",
            "lat": "42.8230519661111",
            "lng": "20.964607558361184",
            "Tel": "",
            "Address": 'Rr. “Hasan Prishtina”'
        },
        {
            "Id": 15,
            "Dega": "PriBank - Dega Prishtinë",
            "lat": "42.655099234956815",
            "lng": "21.178374859263407",
            "Tel": "",
            "Address": 'Rruga B “Ndërtesa e Agrotrade, afër Maxi 24'
        },
    ]
}

const atms = {
    "PriBank": [
        {
            "Id": 1,
            "Dega": "PriBank - Dega Prishtinë",
            "lat": "42.371395",
            "lng": "21.155772",
            "Tel": "",
            "Address": 'Rr. "Ukshin Hoti"'
        },
        {
            "Id": 2,
            "Dega": "PriBank - Dega Ferizaj",
            "lat": "42.371395",
            "lng": "21.155772",
            "Tel": "",
            "Address": 'Rr."Vllezerit Gervalla"'
        },
        {
            "Id": 3,
            "Dega": "PriBank - Dega Fushë Kosovë",
            "lat": "42.63907529062615",
            "lng": "21.0975997734977",
            "Tel": "",
            "Address": 'Rr. "Nenë Tereza"'
        },
        {
            "Id": 4,
            "Dega": "PriBank - Albi Mall ATM",
            "lat": "42.63241879620703",
            "lng": "21.15188472269312",
            "Tel": "",
            "Address": 'Rr. "Nenë Tereza"'
        },
        {
            "Id": 5,
            "Dega": "PriBank - AAB ATM",
            "lat": "42.63902758807998",
            "lng": "21.11217062506849",
            "Tel": "",
            "Address": 'Rr. "Nenë Tereza"'
        },
        {
            "Id": 6,
            "Dega": "PriBank - ATM Gjakovë",
            "lat": "42.38353100765761",
            "lng": "20.42908578645229",
            "Tel": "",
            "Address": 'Rr. "Nëna Terezë, Pn"'
        },
        {
            "Id": 7,
            "Dega": "PriBank - ATM Mitrovicë",
            "lat": "42.888742829499925",
            "lng": "20.872269434077733",
            "Tel": "",
            "Address": 'Rr. "Shemsi Ahmeti, Nr.04"'
        },
        {
            "Id": 8,
            "Dega": "PriBank - ATM Drenas",
            "lat": "42.625481781672796",
            "lng": "20.89527291987945",
            "Tel": "",
            "Address": 'Rr. "Fehmi Ladrovci, Nr.5"'
        },
        {
            "Id": 9,
            "Dega": "PriBank - ATM Lipjan",
            "lat": "42.521264330840495",
            "lng": "21.121830960066536",
            "Tel": "",
            "Address": 'Rr. "Sheshi Adem Jashari, Nr. 6"'
        },
        {
            "Id": 10,
            "Dega": "PriBank - ATM Suharekë",
            "lat": "42.36270738607858",
            "lng": "20.831981739753182",
            "Tel": "",
            "Address": 'Rr. “Brigada 123” (Përballë stacionit të Policisë)'
        },
        {
            "Id": 11,
            "Dega": "PriBank - ATM Vushtrri",
            "lat": "42.8230519661111",
            "lng": "20.964607558361184",
            "Tel": "",
            "Address": 'Rr. “Hasan Prishtina”'
        },
        {
            "Id": 12,
            "Dega": "PriBank - ATM Prizren",
            "lat": "42.216413",
            "lng": "20.726392",
            "Tel": "",
            "Address": 'Rr. "Tirana"'
        },
        {
            "Id": 13,
            "Dega": "PriBank - ATM Gjilan",
            "lat": "42.65841",
            "lng": "21.154735",
            "Tel": "",
            "Address": 'Rr. "Adem Jashari"'
        },
        {
            "Id": 14,
            "Dega": "PriBank - ATM Pejë",
            "lat": "42.65723191135249", 
            "lng": "20.29111259713177",
            "Tel": "",
            "Address": 'Rr. Hasan Prishtina, Nr.1, (përballë Optikës Nimani)'
        },
        {
            "Id": 15,
            "Dega": "PriBank - ATM Prishtinë",
            "lat": "42.655099234956815",
            "lng": "21.178374859263407",
            "Tel": "",
            "Address": 'Rruga B “Ndërtesa e Agrotrade, afër Maxi 24'
        },
        {
            "Id": 16,
            "Dega": "PriBank - ATM Prishtinë",
            "lat": "42.65797405063954",
            "lng": "21.13691884119718",
            "Tel": "",
            "Address": 'Rr. Ahmet Krasniqi, Arbëri (Pompa Kastrati)'
        },

    ]
}

export const Banks = {
    banks,
    atms
}