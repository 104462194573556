<template>
    <div class="online-application">
        <div class="online-application-container">

            <div class="banner-container">
                <div class="banner-content">
                    <div class="banner-title">
                        <h1 en="Complaints and Suggestions" sq="Ankesa dhe sugjerime" sr="Žalbe i Predlozi"></h1>
                    </div>
                </div>
            </div>

            <div class="formbold-main-wrapper">
                <div class="formbold-form-wrapper">
                    <div class="title">
                        <h1 en="Complaints and Suggestions" sq="Ankesa dhe Sugjerime" sr="Žalbe i Predlozi"></h1>
                    </div>

                    <div class="formbold-mb-5">
                        <label for="name" class="formbold-form-label" en="Purpose of Writing" sq="Qëllimi i shkrimit" sr="Svrha pisanja"></label>
                        <select placeholder="Qëllimi i shkrimit" class="formbold-form-input" v-model="formData.purpose">
                            <option value="Suggestion" selected="selected" en="Suggestion" sr="Predlog" sq="Sugjerim"></option>
                            <option value="Praise"  en="Praise" sr="Pohvala" sq="Lavdat"></option>
                            <option value="Complaint"  en="Complaint" sr="Žalba" sq="Ankesa"></option>
                        </select>
                    </div>
                    <div class="formbold-mb-5">
                        <label for="name" class="formbold-form-label" en="First Name" sq="Emri" sr="Ime"></label>
                        <input type="text" v-model="formData.firstName" placeholder="Emri" class="formbold-form-input" required />
                    </div>
                    <div class="formbold-mb-5">
                        <label for="name" class="formbold-form-label" en="Last Name" sq="Mbiemri" sr="Prezime">Mbiemri</label>
                        <input type="text" v-model="formData.lastName" placeholder="Mbiemri" class="formbold-form-input" />
                    </div>
                    <div class="formbold-mb-5">
                        <label for="name" class="formbold-form-label" en="Personal Number" sq="Numri personal" sr="Lični broj">Numri personal</label>
                        <input type="text" maxlength="10" v-model="formData.personalNumber" placeholder="Numri personal" class="formbold-form-input" />
                    </div>
                    <div class="formbold-mb-5">
                        <label for="phone" class="formbold-form-label" en="Phone Number" sq="Numri i telefonit" sr="Broj telefona">Numri i telefonit</label>
                        <input type="text" v-model="formData.phoneNumber" placeholder="Numri i telefonit"
                               class="formbold-form-input" />
                    </div>
                    <div class="formbold-mb-5">
                        <label for="email" class="formbold-form-label" en="E-mail" sq="E-mail" sr="E-pošta">E-pošta</label>
                        <input type="email" v-model="formData.email" name="email" id="email" placeholder="E-mail"
                               class="formbold-form-input" />
                    </div>
                    <div class="formbold-mb-5">
                        <label for="name" class="formbold-form-label" en="Comment" sq="Koment" sr="Komentar">Koment</label>
                        <textarea type="text" v-model="formData.comment" placeholder="Koment" class="formbold-form-input" rows="5" />
                    </div>
                    <div>
                        <button class="formbold-btn" @click="insertFormData()" en="Send" sq="Dërgo" sr="Ostavi"></button>
                    </div>
                    <br />

                </div>

                <div class="content-wrapper">
                    <div class="formbold-mb-5">
                        <p>
                            <span en="At PriBank, we prioritize providing superior service to our customers, so your feedback matters to us." sq="Në PriBank, kujdesemi për të ofruar shërbim të shkëlqyer për klientët tanë, prandaj mendimi juaj ka rëndësi për ne." sr="U PriBanku, prioritet nam je pružanje vrhunske usluge našim klijentima, tako da vaše mišljenje za nas ima značaja.">Në PriBank, kujdesemi për të ofruar shërbim të shkëlqyer për klientët tanë, prandaj mendimi juaj ka rëndësi për ne.</span>
                            <br /><br />
                            <span en="Whether you are satisfied or have any complaints about our service or banking products, please write to us." sq="Pavarësisht nëse jeni të kënaqur ose keni ankesa për shërbimin ose produktet tona bankare, ju lutemi na shkruani." sr="Bilo da ste zadovoljni ili imate pritužbe u vezi sa našom uslugom ili bankarskim proizvodima, molimo vas da nam pišete.">Pavarësisht nëse jeni të kënaqur ose keni ankesa për shërbimin ose produktet tona bankare, ju lutemi na shkruani.</span>
                            <br /><br />
                            <span en="We welcome your suggestions, and through your complaints, you help us improve our service." sq="Ne mirëpresim çdo sugjerim tuajin dhe përmes ankesave tuaja, ju na ndihmoni që ne të përmirësojmë shërbimin tonë." sr="Dobrodošli smo na vaše sugestije i kroz vaše pritužbe, vi nam pomažete da unapredimo našu uslugu.">Ne mirëpresim çdo sugjerim tuajin dhe përmes ankesave tuaja, ju na ndihmoni që ne të përmirësojmë shërbimin tonë.</span>
                            <br /><br />
                            <span en="You can submit your complaint or suggestion to us by filling out the information below:" sq="Ankesën apo sugjerimin tuaj mund ta ndani me ne duke plotësuar të dhënat mëposhtë:" sr="Vašu pritužbu ili sugestiju možete nam poslati popunjavanjem informacija u nastavku:">Ankesën apo sugjerimin tuaj mund ta ndani me ne duke plotësuar të dhënat mëposhtë:</span>
                        </p>
                    </div>
                    <div class="formbold-mb-5">
                        <p>
                            <span en="We will acknowledge the receipt of your complaint and notify you of the unique complaint reference number, which will serve as the reference number for your complaint." sq="Ne do të ju konfirmojmë pranimin e ankesës dhe do të ju njoftojmë lidhur me numrin unik të ankesës tuaj, i cili do të shërbej si numër referues i ankesës." sr="Potvrdićemo prijem vaše pritužbe i obavestićemo vas o jedinstvenom broju za referencu pritužbe, koji će služiti kao broj referenca za vašu pritužbu.">Ne do të ju konfirmojmë pranimin e ankesës dhe do të ju njoftojme lidhur me numrin unik të ankesës tuaj, i cili do të shërbej si numër referues i ankesës.</span>
                            <br />
                            <span en="You will receive a final response within 15 calendar days after confirming the receipt of the complaint." sq="Ju do të pranoni përgjigjen përfundimtare në afat kohor prej 15 ditëve kalendarike pas konfirmimit të pranimit të ankesës." sr="Konačan odgovor će vam biti dostavljen u roku od 15 kalendarskih dana nakon potvrde prijema pritužbe.">Ju do të pranoni përgjigjen përfundimtare në afat kohor prej 15 ditëve kalendarike pas konfirmimit të pranimit të ankesës.</span>
                            <br />
                            <span en="We assure you that your complaint will be treated with seriousness, fairness, and equality!" sq="Ne ju sigurojmë se ankesa juaj do të trajtohet me seriozitet, në mënyrë të drejtë e të barabartë!" sr="Obećavamo vam da će vaša pritužba biti tretirana ozbiljno, pravično i jednakosti!">Ne ju sigurojmë se ankesa juaj do të trajtohet me seriozitet, në mënyrë të drejtë e të barabartë!</span>
                        </p>
                        <br />

                        <p>
                            <span en="You can also contact us through alternative channels:" sq="Mund të na kontaktoni edhe përmes kanaleve tjera alternative:" sr="Takođe nas možete kontaktirati i preko alternativnih kanala:">Mund të na kontaktoni edhe përmes kanaleve tjera alternative:</span>
                        </p>
                        <div class="div1">
                            <p en="Phone Number:" sq="Numër telefoni:" sr="Broj telefona:">Numër telefoni:</p><p>+383 38 74 77 77; 0 800 74 777</p>
                        </div>
                        <div class="div2">
                            <p en="Email:" sq="Email:" sr="Email:">Email:</p><p>kujdesindajklientit@pribank-ks.com</p>
                        </div>
                        <div class="div3">
                            <p en="Address:" sq="Adresa:" sr="Adresa:">Adresa:</p><p>Rr. Sicilia, Kalabria, Prishtinë</p>
                        </div>
                    </div>
                </div>
            </div>




        </div>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from "vuex";
import { externalScript } from "../../assets/external";
    export default {
        data() {
            return {
                formData: {
                    purpose: '',
                    firstName: '',
                    lastName: '',
                    personalNumber: '',
                    phoneNumber: '',
                    email: '',
                    comment: ''
                }
            }
        },
        methods: {
            ...mapActions(["fetchCategories", "fetchPosts", "fetchMedia", "fetchMedias", "insertComplaintSuggestionForm"]),
            getPostMedia(id) {
                let media = this.getMedias.find(x => x.id === id);
                return media?.source_url;
            },
            async insertFormData() {
                var bodyFormData = new FormData();
                bodyFormData.append('purpose', this.formData.purpose);
                bodyFormData.append('firstName', this.formData.firstName);
                bodyFormData.append('lastName', this.formData.lastName);
                bodyFormData.append('personalNumber', this.formData.personalNumber);
                bodyFormData.append('phoneNumber', this.formData.phoneNumber);
                bodyFormData.append('email', this.formData.email);
                bodyFormData.append('comment', this.formData.comment);
                bodyFormData.append('_wpcf7_unit_tag', 15);
                await this.insertComplaintSuggestionForm({
                    id: 15,
                    formData: bodyFormData,
                    successCallback: () => {
                        this.clearFormData();
                        //this.$parent.currentPage = Math.ceil(this.$parent.getAssessmentQuestions.length / this.$parent.perPage);
                        console.log('success');
                        externalScript.popup(" ", "Forma juaj është dërguar me sukses! <br /> Për detaje të mëtutjeshme do të kontaktoheni nga Këshilltarët tanë për klientë!")
                    }
                });
            },
            clearFormData() {
                this.formData.purpose = '';
                this.formData.firstName = '';
                this.formData.lastName = '';
                this.formData.personalNumber = '';
                this.formData.phoneNumber = '';
                this.formData.email = '';
                this.formData.comment = '';
            }
        },
        computed: {
            ...mapGetters(["getCategories", "getPosts", "getMedia", "getMedias"]),
            getAboutUs() {
                return this.getCategories.find(x => x.slug === 'about-us')
            },
            getAboutUsPosts() {
                return this.sortedPosts.filter(x => x.categories[0] == this.getAboutUs.id)
            },
            sortedPosts() {
                return [...this.getPosts].sort((a, b) => a.id - b.id)
            },
        },
        async mounted() {
            setTimeout(()=> externalScript.reloadLanguage(), 300);
        },
    };
</script>

<style>
    @import "../../assets/styles/complaints-and-suggestions.css";
</style>